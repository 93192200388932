<template>
  <div class="candidates">
    <header class="filters">
      <states-filter
        v-model="filters.states"
        :statuses="statuses"
        :counter="counter">
      </states-filter>
      <csv :searches="filters.requirements" :states="filters.states"/>
    </header>
    <div class="candidates-container">
      <section class="candidate-list">
        <article class="candidate-list_container">
          <table class="table">
            <thead>
              <tr>
                <th>Candidat</th>
                <th>Métier</th>
                <th>Statut</th>
              </tr>
            </thead>
            <lazy-interview
              :filters="filters"
              :statuses="statuses"
              :order="'created'"
              @counter="onCounter">
              <template #interview="{interview}">
                <candidate-row
                  :selected="interview.id === $route.params.interview"
                  :key="interview.id"
                  :interview="interview"
                  :reasons="reasons">
                </candidate-row>
              </template>
            </lazy-interview>
          </table>
        </article>
      </section>
      <div v-html="search && search.description" class="search-description"></div>
    </div>
  </div>
</template>
<script>
import Candidate from '/user/candidate/candidate.entity'
import CandidateRow from '/user/conciliator/candidate.row'
import Search from '/requirement/entity'
import Filter from '/filter/'
import order from '/interview/order'
import StatesFilter from '/filter/states'
import LazyInterview from '/interview/lazy'
import Csv from '/interview/csv'
import statuses from '/interview/ordered.status.js'
import Conciliation from '/interview/interview.js'
import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  name: 'hippolyte.conciliator.candidates.list',
  components: { CandidateRow, StatesFilter, LazyInterview, Csv },
  props: {
    search: Search,
    filters: Filter
  },
  data () {
    return {
      reasons: [],
      comparator: 'conciliatedAt',
      counter: statuses.reduce((acc, s) => Object.assign(acc, { [s]: 0 }), { all: 0 }),
      statuses
    }
  },
  mounted () {
    this.loadReasons()
  },
  methods: {
    async loadReasons () {
      try {
        this.cancel = CancelToken.source()
        const reasons = await Conciliation.getStatusReasons({}, this.$socket, this.cancel.token)
        if (reasons.numFound) {
          this.reasons.splice(0, this.reasons.length, ...reasons.docs)
        }
      } catch (err) {
          if (!axios.isCancel(err)) {
            throw err
          }
      }
    },
    onCounter (counter) {
      Object.assign(this.counter, counter)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'

.candidates
  height 100vh
  header
    height 2.5em
    display flex
    align-content center
    &> *
      height 2em
      margin auto 0.4em
.candidate-list
  height 100%
  flex 2
  article.candidate-list_container
    overflow-y auto
    overflow-x hidden
    height calc(100% - 4em)
    table
      th
        z-index 2
        position sticky
        top 0
      border-collapse collapse
      font-size 0.9em
      width 100%
      thead
        color black
        font-size 0.9em
        th
          background #faeda7
          padding 10px 10px
          text-align left
          &:first-child
            padding-left 15px
.candidates-container
  display flex
  width 100%
  height 100%
.search-description
  flex 1
  height 100%
  width 20em
  padding 1em
  background-color #f7fafd
  border-right 1px solid white
  overflow scroll
</style>
