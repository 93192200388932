<template>
  <tbody>
    <template v-for="interview in interviews">
      <slot name="interview" :interview="interview"></slot>
    </template>
    <tr ref="observable" style="background-color: transparent;">
      <td colspan="10">
        <spinner v-if="loading || counting || countingStates" style="transform: scale(0.2, 0.2);"></spinner>
      </td>
    </tr>
  </tbody>
</template>
<script>
import Filters from '/filter'
import order from '/interview/order'
import Conciliation from '/interview/interview.js'
import Recruiter from '/user/recruiter/recruiter.entity'
import Candidate from '/user/candidate/candidate.entity'
import axios from 'axios'
import Spinner from '/layout/spinner'
import { Status } from '/interview/status'
const CancelToken = axios.CancelToken

export default {
  name: 'hippolyte.recruiter.candidates.lazy.list',
  components: { Spinner },
  props: {
    filters: Filters,
    recruiter: Recruiter,
    statuses: Array,
    order: {
      Validator: o => o in Status,
      default: Status.sent
    }
  },
  data () {
    return {
      count: null,
      interviews: [],
      searches: this.filters.requirements,
      states: this.filters.states,
      comparator: 'sentAt',
      dir: 'desc',
      counter: this.statuses.reduce((acc, s) => Object.assign({ [s]: 0 }), { all: 0 }),
      loading: null,
      counting: null,
      countingStates: null
    }
  },
  watch: {
    searches: async function (searches, old) {
      if (!this.interviews.length || this.interviews[0].search.id !== searches[0]) {
        this.resetList()
        this.cancelCountStates?.cancel()
        await this.statesCount()
        return this.load()
      }
    },
    states: function () {
      this.resetList()
      this.load()
    }
  },
  mounted () {
    this.$loading.requirements.then(() => this.statesCount())
    this.$nextTick(() => {
      this.observer = new IntersectionObserver(this.onReachBottom.bind(this), { root: this.$parent.$el})
      this.observer.observe(this.$refs.observable)
    })
  },
  destroyed () {
    this.observer.disconnect()
    this.interviews.map(i => i.off('update', this.onInterviewUpdate, this))
  },
  methods: {
    async onReachBottom (entries, observer) {
      const entrie = entries.find(e => e.target === this.$refs.observable)
      if (entrie?.isIntersecting) {
        if (await this.load()) {
          this.resetObserver()
        }
      }
    },
    async load () {
      let loaded = 0
      if (this.loading) {
        return this.loading
      }
      if (this.count === null || this.count > this.interviews.length) {
        try {
          this.cancel = CancelToken.source()
          this.loading = Conciliation.list({
            solr: true,
            current: true,
            recruiter: this.recruiter,
            searches: this.searches.map(s => ({ id: s })),
            states: this.states,
            dir: this.dir,
            order: {
              status: this.order,
              field: 'date'
            },
            includes: {
              search: {
                location: true,
                trade: true
              }
            },
            limit: 10,
            offset: this.interviews.length
          }, this.$socket, this.cancel.token)
          const list = await this.loading
          this.count = this.count ?? list.numFound
          list.docs.map(i => i.on('update', this.onInterviewUpdate, this))
          this.interviews.push(...list.docs.sort((a, b) => a.candidate.createdAt > b.candidate.createdAt ? -1 : 1))
          loaded = list.docs.length
        } catch (err) {
          if (!axios.isCancel(err)) {
            throw err
          }
        }
      }
      this.cancel = null
      this.loading = null
      return loaded
    },
    async statesCount () {
      this.countingStates = true
      this.cancelCountStates = CancelToken.source()
      return Conciliation.list({
        solr: true,
        limit: this.statuses.length,
        recruiter: this.recruiter,
        searches: this.searches.map(s => ({ id: s })),
        count: true
      }, this.$socket, this.cancelCountStates.token)
      .then(count => {
        this.count = count.facets.count
        Object.keys(this.counter).map(k => this.counter[k] = 0)
        this.counter.all = count.facets.count
        if (count.facets.count) {
          count.facets.status.buckets.map(s => {
            this.counter[s.val] = s.count
          })
        }
        this.$emit('counter', this.counter)
        this.countingStates = false
      })
    },
    resetObserver () {
      if (this.$refs.observable && this.$refs.observable instanceof Element) {
        this.observer.unobserve(this.$refs.observable)
        this.observer.observe(this.$refs.observable)
      }
    },
    resetList () {
      this.interviews.map(i => i.off('update', this.onInterviewUpdate, this))
      this.interviews.splice(0, this.interviews.length)
      this.count = null
      this.resetObserver()
      this.cancel?.cancel()
    },
    onInterviewUpdate (interview, delta={}) {
      if (delta.status && interview.status !== delta.status) {
        this.counter[interview.status]++
        if (this.statuses.includes(delta.status)) {
          this.counter[delta.status]--
        }
        this.$emit('counter', this.counter)
        if (!this.states.includes(interview.status)) {
          const at = this.interviews.indexOf(interview)
          if (at > -1) {
            this.interviews.splice(at, 1)
            interview.off('update', this.onInterviewUpdate, this)
          }
        }
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
tbody
  z-index 1
  tr
    border-bottom 1px solid $color-blue_lighter
</style>
