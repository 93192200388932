<template>
  <Searches :requirements="requirements" :selected="selected" :selectedRecruiter="selectedRecruiter">
      <template v-slot:menu-header>
        <header>
          <b>Recherches</b>
        </header>
      </template>
      <template v-slot:recruiter="slotProps">
        <div class="recruiter-name">
          <h4 @click="slotProps.toggleRequirements(slotProps.index, slotProps.id)" class="recruiter-name-content">{{ slotProps.recruiter.company }}</h4>
        </div>
      </template>
      <template v-slot:edit-button="slotProps">
        <button @click="openBoard(slotProps.id)" class="edit-search-btn" title="Editer">
          <svg
            viewBox="-15 -15 484.00019 484"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-width="1" d="m401.648438 18.234375c-24.394532-24.351563-63.898438-24.351563-88.292969 0l-22.101563 22.222656-235.269531 235.144531-.5.503907c-.121094.121093-.121094.25-.25.25-.25.375-.625.746093-.871094 1.121093 0 .125-.128906.125-.128906.25-.25.375-.371094.625-.625 1-.121094.125-.121094.246094-.246094.375-.125.375-.25.625-.378906 1 0 .121094-.121094.121094-.121094.25l-52.199219 156.96875c-1.53125 4.46875-.367187 9.417969 2.996094 12.734376 2.363282 2.332031 5.550782 3.636718 8.867188 3.625 1.355468-.023438 2.699218-.234376 3.996094-.625l156.847656-52.324219c.121094 0 .121094 0 .25-.121094.394531-.117187.773437-.285156 1.121094-.503906.097656-.011719.183593-.054688.253906-.121094.371094-.25.871094-.503906 1.246094-.753906.371093-.246094.75-.621094 1.125-.871094.125-.128906.246093-.128906.246093-.25.128907-.125.378907-.246094.503907-.5l257.371093-257.371094c24.351563-24.394531 24.351563-63.898437 0-88.289062zm-232.273438 353.148437-86.914062-86.910156 217.535156-217.535156 86.914062 86.910156zm-99.15625-63.808593 75.929688 75.925781-114.015626 37.960938zm347.664062-184.820313-13.238281 13.363282-86.917969-86.917969 13.367188-13.359375c14.621094-14.609375 38.320312-14.609375 52.945312 0l33.964844 33.964844c14.511719 14.6875 14.457032 38.332031-.121094 52.949218zm0 0"/>
          </svg>
        </button>
      </template>
      <template v-slot:counter="slotProps">
        <div class="recruiter-counter">
          <span>{{ slotProps.getCount(slotProps.id) }}</span>
        </div>
      </template>
      <template v-slot:menu-search="slotProps">
        <div :key="slotProps.requirement.id" :class="{'requirement-item_container': true, selected: hasRequirement(slotProps.requirement.id) }">
          <div @click="toggle(slotProps.requirement.id)" class="requirement-infos-content">
            <requirement-item :requirement="slotProps.requirement" showTag :showActivity="false"></requirement-item>
          </div>
        </div>
      </template>
  </Searches>
</template>

<script>
import Searches from '/menu/searches'
import RequirementItem from '/menu/requirement'

export default {
  name: 'hippolyte.conciliator.menu.candidates_searches',
  props: {
    selected: Array,
    selectedRecruiter: String
  },
  data () {
    return {
      requirements: this.$requirements
    }
  },
  components: {
    Searches,
    RequirementItem
  },
  methods: {
    hasRequirement (search) {
      return this.selected.includes(search)
    },
    toggle (search) {
      if (this.selected.includes(search)) {
        this.$router.push({
          name: 'candidate_list',
          query: Object.assign({}, this.$router.currentRoute.query)
        })
      } else {
        this.$router.push({
          name: 'candidate_list',
          params: { search },
          query: Object.assign({}, this.$router.currentRoute.query)
        })
      }
    },
    openBoard (id) {
      try {
        window.open([
          document.location.protocol, '',
          document.location.host.replace('conciliator', 'conciliateur'),
          'recruiter',
          id,
          'profile'
        ].join('/'))
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'

</style>

