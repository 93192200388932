<template>
  <section class="main-config">

    <article class="config-container">
      <header><b>Recruteurs</b></header>
      <div class="recruiter-search">
        <input @keyup="searchRecruiter" type="text" v-model="recruiterSearch" class="recruiter-search-input"/>
      </div>
      <spinner v-if="loading.recruiter" style="transform: scale(0.2, 0.2);"></spinner>
      <table v-else class="recruiters">
        <tbody>
          <tr v-for="recruiter in recruiters" @click="selectRecruiter(recruiter)" :class="{ selected: selectedRecruiter && selectedRecruiter.id === recruiter.id}">
            <td>{{ recruiter.company }}</td>
          </tr>
        </tbody>
      </table>
      <footer class="paginate">
        <paginate :count="count" v-model="offset" :limit="limit"></paginate>
      </footer>
    </article>

    <article v-if="selectedRecruiter" class="config-container">
      <header><b>Recherches</b></header>
      <search-list :recruiter="selectedRecruiter" @select="selectSearch"></search-list>
    </article>

    <article v-if="selectedRecruiter" class="config-container config-muted">
      <header>
        <h4>Mute</h4>
        {{selectedRecruiter.company}}{{ selectedSearch ? ` - ${selectedSearch.title}` : '' }}
        <button @click="mute(selectedSearch.id)" v-if="features.mute && selectedSearch && !muted.find((s) => s.id === selectedSearch.id)" title="ne pas envoyer à l'ats">🔊</button>
      </header>
      <section v-if="features.mute && muted.length">
        <header title="Les recherches marquées comme 'muted' n'enveront aucun candidat à l'ats cible."><b>Muted</b></header>
        <ul v-if="features.mute">
          <li v-for="search in muted" :key="search.id">
            {{ search.title }}
            <button @click="mute(search.id, false)" title="envoyer à l'ats">🔇</button>
          </li>
        </ul>
      </section>
    </article>

    <article v-if="selectedRecruiter" class="config-container">
      <article class="configRecruiter">
        <header>
          <select name="service" id="service" @change="selectService">
            <option v-for="(service,i) in services" :value="service" :selected="selectedService===service" :key="i">
              {{ service }}
              <spinner v-if="loading[service] || saving[service]" style="transform: scale(0.2, 0.2);"></spinner>
              <span v-if="servicesConfig[service].active" :class="{ activeicon: true }"> ✔</span>
            </option>
          </select>
        </header>
        <div v-if="selectedService">
          <div>
            <header><b>Config</b><i v-if="servicesConfig[selectedService] && servicesConfig[selectedService].versions" :title="getVersions()">ℹ️</i></header>
            <component
              :savingConf="saving[selectedService]"
              :is="selectedService + '-config'"
              v-model="servicesConfig[selectedService]"
              v-if="!loading[selectedService]"
              :recruiter="selectedRecruiter"
              :search="selectedSearch"
              @input="onUpdateConfig">
            </component>
          </div>
          <nearest v-if="selectedSearch && nearestServices.includes(selectedService)"
            :field="selectedService === 'geomail' ? 'emails' : 'reference'"
            :multiple="selectedService === 'geomail'"
            :search="selectedSearch"
            :recruiter="selectedRecruiter"
            :service="selectedService" >
          </nearest>
        </div>
      </article>
    </article>

  </section>
</template>
<script>
import searchList from './searchList'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Paginate from '/layout/paginate'
import clone from 'ramda/src/clone'
import { eq } from '/solr'
import Spinner from '/layout/spinner'
import { isAtLeast } from '../fun'
// ATS
import AltaysConfig from './altays'
import AtomeConfig from './atome'
import AudioConfig from './audio'
import BeetweenConfig from './beetween'
import Config from './teamtailor'
import CareerbuilderConfig from './careerbuilder'
import CornerstoneConfig from './cornerstone'
import DigitalrecruitersConfig from './digitalrecruiters'
import EmailConfig from './email'
import EoliaConfig from './eolia'
import FlatchrConfig from './flatchr'
import GeomailConfig from './geomail'
import GestmaxConfig from './gestmax'
import GreenhouseConfig from './greenhouse'
import HandcraftConfig from './handcraft'
import HorizontalConfig from './horizontal'
import IcimsConfig from './icims'
import JobaffinityConfig from './jobaffinity'
import JobplusConfig from './jobplus'
import MergeAtsConfig from './merge'
import Nearest from './nearest'
import SamsicConfig from './samsic'
import SmartrecruitersConfig from './smartrecruiters'
import TalentviewConfig from './talentview'
import TalentsoftConfig from './talentsoft'
import TalentlinkConfig from './talentlink'
import TeamtailorConfig from './teamtailor'
import Werecruit2Config from './werecruit'
import WorkdayConfig from './workday'
import TalentsoftFrontofficeConfig from './talentsoft-frontoffice'
import TalentplugConfig from './talentplug'
const EfcConfig = EmailConfig

export default {
  name: 'hippolyte.services.config',
  components: { searchList, Spinner, EmailConfig, CareerbuilderConfig, AudioConfig, EfcConfig, Nearest, Paginate, GeomailConfig, TalentsoftConfig, TalentlinkConfig, TalentviewConfig, MergeAtsConfig, TeamtailorConfig, Werecruit2Config, FlatchrConfig, SmartrecruitersConfig, GestmaxConfig, JobaffinityConfig, WorkdayConfig, DigitalrecruitersConfig, JobplusConfig, EoliaConfig, TalentsoftFrontofficeConfig, TalentplugConfig, AltaysConfig, HorizontalConfig, BeetweenConfig, HandcraftConfig, IcimsConfig, AtomeConfig, SamsicConfig, CornerstoneConfig, GreenhouseConfig },
  props: {
    recruiter: Recruiter,
    ats: null,
    query: {
      type: Object,
      default () {
        return { offset: 0, limit: 10, search: null, query: '' }
      }
    }
  },
  data () {
    const services = ['careerbuilder', 'talentsoft', 'talentlink', 'talentview', 'email', 'audio', 'efc', 'geomail', 'merge-ats', 'teamtailor', 'werecruit2', 'flatchr', 'smartrecruiters', 'gestmax', 'jobaffinity', 'workday', 'digitalrecruiters', 'jobplus', 'eolia', 'talentsoft-frontoffice', 'talentplug', 'altays', 'horizontal', 'beetween', 'handcraft', 'icims', 'atome', 'samsic', 'cornerstone', 'greenhouse'].sort()
    const nearest = ['geomail']
    const loading = services.reduce((acc, s) => Object.assign(acc, { [s]: false }), { recruiter: false })
    const servicesConfig = services.reduce((acc, a) => Object.assign(acc, { [a]: { active: false, credential: {}, filters: [] } }), {})
    return {
      loading,
      saving: clone(loading),
      recruiters: [],
      searches: [],
      offset: this.query.offset || 0,
      limit: this.query.limit || 10,
      selectedRecruiter: this.recruiter,
      selectedSearch: this.query.search || null,
      selectedService: this.ats,
      count: 0,
      services,
      servicesConfig,
      nearestServices: nearest,
      nearest: { coords: { longitude: '', latitude: '' }, reference: '' },
      recruiterSearch: this.query.query || '',
      muted: [],
      versions: {}
    }
  },
  watch: {
    offset () {
      this.load()
    },
    selectedSearch () {
      this.loadConfig('geomail')
    },
    selectedRecruiter () {
      this.selectSearch(null)
      this.loadMuted(this.selectedRecruiter.id)
    },
    recruiterSearch () {
      this.offset = 0
    },
    selectedService () {
      this.loadMuted(this.selectedRecruiter.id)
    }
  },
  mounted () {
    this.searchRecruiter()
    if (this.recruiter) {
      this.selectRecruiter(this.recruiter, false)
    }
  },
  methods: {
    onUpdateConfig(config) {
      const service = this.selectedService
      let saving
      this.saving[service] = true
      let opts = {}
      if (service === 'geomail') {
        opts.search = this.selectedSearch.id
      } else {
        opts.recruiter = this.selectedRecruiter.id
      }
      if (['efc', 'careerbuilder', 'geomail', 'talentsoft', 'talentlink', 'talentview', 'teamtailor', 'werecruit2', 'flatchr', 'smartrecruiters', 'jobaffinity', 'digitalrecruiters', 'jobplus', 'eolia', 'talentsoft-frontoffice', 'talentplug', 'altays', 'horizontal', 'beetween', 'handcraft', 'workday', 'icims', 'atome', 'samsic', 'cornerstone', 'greenhouse'].includes(service)) {
        saving = this.$socket.service(`${service}.config/SAVE`, Object.assign(opts, this.servicesConfig[service]))
      } else {
        saving = this.$socket.service(`${service}.config/SAVE`, Object.assign(opts, config))
        if (service === "merge-ats") {
          saving.then(data => Object.assign(this.servicesConfig[service], data))
        }
      }
      saving.then(
        () => {
          this.$toast('Configuration sauvegardée', { theme: 'success' })
        },
        () => {
          this.$toast('Erreur lors de la sauvegarde de la configuration', { theme: 'error' })
        }
      )
      this.servicesConfig[service] = config
      saving.finally(() => {
        this.saving[service] = false
      })
    },
    async load () {
      this.loading.recruiters = true
      let opts = {
        limit: this.limit,
        offset: this.offset,
        solr: true
      }

      if (this.recruiterSearch) {
        opts.company = this.recruiterSearch
      }

      const list = await Recruiter.search(opts, this.$socket)
      this.recruiters.splice(0, this.recruiters.length, ...list.docs.map(data => new Recruiter(data, this.$socket)))
      this.count = list.numFound
      this.loading.recruiter = false
    },
    async selectRecruiter (recruiter, route=true) {
      if (this.selectedRecruiter !== recruiter) {
        this.selectedSearch = null
        this.soffset = 0
      }
      this.selectedRecruiter = recruiter
      Recruiter.search({ solr: true, limit: 1, query: eq('id', recruiter.id) }, this.$socket)
        .then(r => this.recruiter.setData(r.docs[0]))
        .then(() => this.searches.splice(0, this.searches.length, ...recruiter.searches.docs.map(s => new Search(s, this.$socket))))
      Promise.allSettled(this.services.map(service => this.loadConfig(service)))
        .then(() => this.versions = this.servicesConfig[this.selectedService].versions || {})
        .then(() => this.loadMuted(recruiter.id))
      if (route) {
        this.$router.push({ name: 'ats', params: { recruiter: recruiter.id, ats: this.selectedService }, query: this.routeQuery() })
      }
    },
    async loadConfig (service) {
      if (service === 'geomail' && !this.selectedSearch) {
        this.servicesConfig[service] = { active: false }
        return
      }
      this.loading[service] = true
      return this.$socket.service(`${service}.config/GET`, service === 'geomail' ? { search: this.selectedSearch.id} : { recruiter: this.selectedRecruiter.id })
        .then(
          c => {
            Object.keys(this.servicesConfig[service]).map(k => {
              delete this.servicesConfig[service][k]
            })
            Object.assign(this.servicesConfig[service], c)
            if (typeof this.servicesConfig[service].credential !== 'object') {
              this.servicesConfig[service].credential = {}
            }
            if (!Array.isArray(this.servicesConfig[service].filters)) {
              this.servicesConfig[service].filters = []
            }
          },
          () => {
            Object.keys(this.servicesConfig[service]).map(k => {
              delete this.servicesConfig[service][k]
            })
            Object.assign({ active: false, credential: {}, filters: [] }, this.servicesConfig[service])
          }
        ).finally(() => {
          this.loading[service] = false
        })
    },
    selectService (event) {
      this.selectedService = event.target.value
      this.$router.push({ name: 'ats', params: { recruiter: this.recruiter.id, ats: event.target.value }, query: this.routeQuery() })
    },
    selectSearch (search) {
      this.selectedSearch = search
      this.$router.push({ name: 'ats', params: { recruiter: this.recruiter.id, ats: this.selectedService }, query: this.routeQuery() })
    },
    routeQuery () {
      const query = {}
      if (this.recruiterSearch.length >= 2) {
        query.query = this.recruiterSearch
      }
      if (this.offset) {
        query.offset = this.offset
      }
      if (this.limit !== 10) {
        query.limit = this.limit
      }
      if (this.selectedSearch) {
        query.search = this.selectedSearch.id
      }
      return query
    },
    async searchRecruiter () {
      if (this.recruiterSearch && this.recruiterSearch !== '') {
        let opts = {
          limit: this.limit,
          offset: this.offset,
          solr: true,
          company: this.recruiterSearch
        }
        const newList = await Recruiter.search(opts, this.$socket)
        this.recruiters.splice(0, this.recruiters.length, ...newList.docs.map(data => new Recruiter(data, this.$socket)))
        this.count = newList.numFound
        this.$router.push({ name: 'ats', params: { recruiter: this.recruiter?.id, ats: this.selectedService }, query: this.routeQuery() })
      }
      if (this.recruiterSearch === '') {
        return this.load()
      }
    },
    getVersions () {
      return Object.entries(this.versions).map(([k, v]) => `${k} ${v}`).join(' - ')
    },
    async mute (search, mute=true) {
      await this.$socket.service(`${this.selectedService}.mute/UPDATE`, { search, mute })
      return this.loadMuted(this.selectedRecruiter.id)
    },
    async loadMuted (recruiter) {
      try {
        const { docs } = await this.$socket.service(`${this.selectedService}.mute/LIST`, { recruiter })
        this.muted.splice(0, this.muted.length, ...docs)
      } catch (err) {
        this.muted.splice(0, this.muted.length)
      }
    }
  },
  computed: {
    features () {
      return {
        mute: this.selectedService && isAtLeast(this.versions, 'sdk', 2, 6, 0)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.main-config
  flex-direction column
  overflow scroll
  height 100%
  .config-container
    display block
    border-radius 5px
    background #eee
    margin 1em
    width 98%
    p
      margin 1em
    .recruiter-search
      margin 1em
      width 30%
      &-input
        border none
        border-radius 5px
        width 100%
  header
    margin 1em
    overflow scroll
    width 95vw
  .config-muted
    ul
      list-style-type none
      padding 0 1em
      margin 0
  table
    margin 1em
    border-spacing 0
    tr
      cursor pointer
      &.fake
        height 32.8px
      td
        color: #0a2e36
        padding 0.4em 1em 0.4em 0.4em
    .selected
      border-right 6px #45ADA7 solid
      background alpha($color-astronaut, 0.2)
  article
    select
      margin 2em 0 1em 15em
      font-size 16px
      background-color transparent
      .activeicon
        color #45ADA7
        opacity 0
      .isActive
        opacity 1
  input
    border 1px #0a2e36 solid
    border-radius 3px
    height 2em
  input[type="checkbox"]
    height auto
  label
    margin-right 1em
  .nearest
    padding-bottom 1em
  article.search
    overflow scroll
    max-height 30vh
  button
    background white
    border none
    color black
    border-radius 2em
    line-height 1em
    margin 0.2em 0
    padding 0.4em 1.5em
    font-size 0.9em
    cursor pointer
    text-decoration none
    &::first-letter
      text-transform capitalize
    &:focus, &:hover
      outline none
    &.invert
      color white
      border 1px white solid
    &:disabled
      color #ccc
      cursor default
</style>
