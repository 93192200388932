<template>
  <form @submit.prevent="onSubmit">
    <header class="hippo-form_inline error_container">
      <input placeholder="Libellé" name="name" v-model="name" @focus="onFocus('name')"/>
      <span class="error" v-if="errors.name">{{errors.name}}</span>
    </header>
    <span class="wrapper">
      <span class="error_container">
        <snippet class="hippo-form_inline"
          contenteditable
          ref="editable"
          :class="{placeholder, contenteditable: true }"
          @input="onWrite"
          @focus="onFocus('template')"
          tabindex="0"
          :template="template" v-if="show"
          >
        </snippet>
        <span class="error" v-if="errors.template">{{errors.template}}</span>
      </span>
      <p class="help">Sélectionnez le texte à remplacer dans le message et insérez un champs en cliquant dessus.</p>
      <ul class="snippet-fields">
        <li v-for="(field, i) in fields" :key="i" @mousedown="tag({ entity: field.entity, field: field.name, label: field.label })">
          <snippet :template="[{ entity: field.entity, field: field.name, value: field.label }]"/>
        </li>
      </ul>
    </span>
    <p class="hippo-form_inline">
      <button class="hippo-form_actions default" @click.stop.prevent="remove({id})" v-if="id">Supprimer</button>
      <button type="submit" class="hippo-form_actions form-submit">
        {{ id ? 'Modifier' : 'Enregistrer le message' }}
      </button>
    </p>
  </form>
</template>
<script>
import pick from 'ramda/src/pick'
import Snippet from '/snippet/snippet'

export default {
  name: 'hippolyte.snippet.form',
  components: { Snippet },
  props: {
    snippet: {
      type: Object,
      default () {
        return {
          id: null,
          name: '',
          template: []
        }
      }
    },
    others: Array
  },
  data () {
    return {
      id: this.snippet.id,
      name: this.snippet.name,
      template: Array.from(this.snippet.template),
      placeholder: !this.snippet.template.length,
      range: null,
      show: true,
      fields: [
        { entity: 'Candidat', name: 'firstname', label: 'Prénom candidat' },
        { entity: 'Candidat', name: 'lastname', label: 'Nom candidat' },
        { entity: 'Candidat', name: 'email', label: 'Mail candidat' },
        { entity: 'Search', name: 'trade', label: 'Trade'},
        { entity: 'Search', name: 'location', label: 'Localisation'},
        { entity: 'Recruiter', name: 'company', label: 'Recruteur'}
      ],
      errors: {
        name: null,
        template: null
      }
    }
  },
  mounted () {
    this.observe()
  },
  destroyed () {
    this.observer.disconnect()
    this.observer = null
  },
  methods: {
    observe () {
      if (!this.observer) {
        this.observer = new MutationObserver(this.onWrite.bind(this))
        this.observer.observe(this.$refs.editable.$el, {
          childList: true,
          characterData: true,
          subtree: true
        })
      }
    },
    reset () {
      Object.assign(this, {
        id: null,
        name: '',
        selection: null
      })
      this.template.splice(0, this.template.length)
    },
    async onSubmit () {
      await (this.id ? this.update() : this.save())
    },
    async save () {
      const template = this.serialize()
      if (this.validate({ name: this.name, template })) {
        this.show = false
        this.$nextTick(async () => {
          this.template.splice(0, this.template.length, ...template)
          const response = await this.$socket.service('chat.template/CREATE', pick(['template', 'name'], this))
          this.$emit('snippet:created', response)
          this.$nextTick(() => Object.assign(this, { show: true }))
        })
      }
    },
    async update () {
      const template = this.serialize()
      if (this.validate({ name: this.name, template, id: this.id })) {
        this.show = false
        this.$nextTick(async () => {
          this.template.splice(0, this.template.length, ...template)
          const response = await this.$socket.service('chat.template/UPDATE', pick(['id', 'template', 'name'], this))
          this.$emit('snippet:updated', response)
          this.$nextTick(() => Object.assign(this, { show: true }))
        })
      }
    },
    async remove (item) {
      await this.$socket.service('chat.template/DELETE', item)
      this.$emit('snippet:removed', item)
    },
    validate (item) {
      if (item.name.length <= 2) {
        this.errors.name = '2 caractères minimum'
      } else if (item.id && this.others.some(i => i.name === item.name && i.id !== item.id)) {
        this.errors.name = 'Unique'
      }
      if (!item.template.join().trim().length) {
        this.errors.template = 'Vide'
      }
      return !this.errors.name && !this.errors.template
    },
    onFocus (field) {
      this.errors[field] = null
    },
    serialize () {
      const clone = this.$refs.editable.$el.cloneNode(true)
      clone.normalize()
      return Array.from(clone.childNodes).reduce((acc, node) => {
        acc = Array.from(acc)
        if (node.nodeType === Node.TEXT_NODE) {
          acc.push(node.textContent)
        } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'BR') {
          acc.push(null)
        } else if (node.dataset && node.dataset.entity && node.dataset.field) {
          acc.push({
            entity: node.dataset.entity,
            field: node.dataset.field,
            value: node.textContent
          })
        }
        return acc
      }, [])
    },
    onWrite (event) {
      if (this.$refs.editable.$el.textContent.length > 0) {
        this.placeholder = false
      } else {
        this.placeholder = true
      }
    },
    tag (tag) {
      const sel = window.getSelection()
      const range = sel.getRangeAt(0)
      if (!hasParent(range.commonAncestorContainer, this.$refs.editable.$el)) {
        return
      }
      const tagNode = document.createElement('span')
      tagNode.classList.add('entity')
      tagNode.dataset.entity = tag.entity
      tagNode.dataset.field = tag.field
      tagNode.append(tag.label)
      tagNode.title = tag.label
      const start = this.getTag(range.startContainer)
      const end = this.getTag(range.endContainer)
      if (start || end) {
        this.$refs.editable.$el.insertBefore(tagNode, start || end)
        start && start.remove()
        end && end.remove()
        range.deleteContents()
      } else {
        range.deleteContents()
        range.insertNode(tagNode)
      }
      if (!tagNode.nextElementSibling) {
        tagNode.after(document.createTextNode('\u00A0'))
      }
      this.onWrite()
      this.$nextTick(() => this.$refs.editable.$el.focus())
    },
    getTag (node) {
      if (node && node !== this.$refs.editable.$el && node.parentNode) {
        if (node.parentNode === this.$refs.editable.$el) {
          return node.nodeType !== Node.TEXT_NODE ? node : null
        } else {
          return this.getTag(node.parentNode)
        }
      }
      return null
    }
  }
}
function hasParent (el, parent) {
  return el === parent || el.parentNode === parent || (el.parentNode && hasParent(el.parentNode, parent))
}
</script>
<style lang="stylus" scoped>
@require '../colors'
ul
  list-style none
  padding 0
  margin 0
  display flex
  flex-direction row
  flex-wrap wrap
  li
    white-space nowrap
    padding .4em
    cursor pointer
form
  input, .contenteditable
    border 1px #ccc solid
    border-radius 8px
    padding .4em 0 .4em .8em
  margin 1em
  header input
    font-size 1em
    width calc(100% - 1em)
    position relative
  .contenteditable
    margin 1em 0
    word-break break-all
    cursor text
    min-height 4em
    display block
    line-height 1.5em
    &.placeholder::before
      content 'Message'
      color $color-grey
  button
    border-radius 1.5em
    padding 0.2em 1em
    margin-left .5em
    border-radius 1.5em
    padding 0.5em 1em
    font-size 1em
    cursor pointer
    &[type="submit"]
      color #19d089
      border 1px #19d089 solid
      color #19d089
      background white
  p
    margin 0 1em
    &.hippo-form_inline
      display flex
      justify-content flex-end
      margin 0
    &.help
      font-size .8em
  .error_container
    position relative
    .error
      position absolute
      bottom 0.7em
      right 0.7em
      font-size .7em
      color red
  .wrapper .error_container
    display block
</style>
