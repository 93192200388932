<template>
  <article class="simple-donut">
    <svg viewBox="0 0 42 42" width="220" height="220">
      <circle
        class="simple-donut-empty"
        cx="21"
        cy="21"
        :r="radius"
        fill="none"
        stroke-width="2"
      ></circle>
      <circle
        class="simple-donut-filled"
        cx="21"
        cy="21"
        :r="radius"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
      ></circle>
      <text x="22" y="24" class="simple-donut-text">{{ percentage }}%</text>
    </svg>
  </article>
</template>
<script>
export default {
  name: 'hippolyte.reporting.simpleDonut',
  props: {
    radius: { type: Number, default: 13 },
    coefficient: { type: Number, default: 0 }
  },
  computed: {
    dashArray() {
      const filled = this.coefficient * this.getCircumference()
      const empty = this.getCircumference() - filled
      return `${filled} ${empty}`
    },
    dashOffset() {
      return `${this.getCircumference() / 4}`
    },
    percentage() {
      return Math.round(this.coefficient * 100)
    }
  },
  methods: {
    getCircumference() {
      return 2 * Math.PI * this.radius
    }
  }
}
</script>

<style lang="stylus">

@require '~/colors.styl'

.simple-donut-empty
    stroke $fill
.simple-donut-filled
    stroke $hippolyte-blue
.simple-donut-text
    font-family $font
    font-weight bolder
    font-size 7px
    text-anchor: middle
</style>
