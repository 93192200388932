<template>
  <div class="hippolyte-snippet">
    <div class="loader" v-if="cancels.list">
      <loader></loader>
    </div>
    <ul v-else>
      <li @mousedown="select(item)" v-for="item in items" :key="item.id">
        <form-snippet
          v-if="editing && editing.id === item.id"
          :snippet="item"
          @snippet:updated="onUpdated"
          @snippet:removed="onRemoved"
          :class="{selected: editing && editing.id === item.id}"
          :others="items"
        ></form-snippet>
        <form v-else class="snippet_show">
          <header>
            {{item.name}}
            <button class="hippo-form_actions default" @mousedown="select(item)">Modifier</button>
          </header>
          <footer>
          </footer>
        </form>
      </li>
    </ul>
    <p v-if="!creating">
      <button @click.prevent="create">
        Créer un nouveau message rapide
      </button>
    </p>
    <form-snippet
      v-else
      ref="create"
      @snippet:created="onCreated"
      class="snippet_create"
      :class="{selected: creating}"
      :others="items"
    ></form-snippet>
  </div>
</template>
<script>
import Loader from '/layout/spinner'
import Account from '/user/account/entity'
import cancel from '/cancel.mixin'
import FormSnippet from '/snippet/form'

export default {
  name: 'hippolyte.snippet.editor',
  components: { Loader, FormSnippet },
  props: {
    account: Account
  },
  mixins: [cancel],
  data () {
    return {
      items: [],
      editing: null,
      creating: null
    }
  },
  async mounted () {
    this.load()
  },
  methods: {
    select (item) {
      this.editing = item
      this.creating = false
    },
    create () {
      this.editing = null
      this.creating = true
    },
    async load () {
      this.cancel('list')
      try {
        const response = await this.$socket.service('chat.template/LIST', {}, { cancel: this.token('list') })
        this.items.splice(0, this.items.length, ...response.docs)
        this.items.sort(byCreatedAt)
        this.cancel('list', null)
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('list', null)
        })
      }
    },
    async onRemoved (item) {
      this.editing = null
      this.creating = false
      const at = this.items.findIndex(i => item.id === i.id)
      if (at > -1) {
        this.items.splice(at, 1)
      }
    },
    onCreated (snippet) {
      this.items.push(snippet)
      this.items.sort(byCreatedAt)
      this.$refs.create.reset()
      this.creating = false
    },
    onUpdated (snippet) {
      Object.assign(
        this.items.find(s => s.id === snippet.id),
        snippet
      )
      this.editing = null
    }
  }
}
function byCreatedAt (a, b) {
  a = new Date(a.createdAt)
  b = new Date(b.createdAt)
  return a === b ? 0 : (a > b ? 1 : -1)
}
</script>
<style lang="stylus" scoped>
@import '../colors'
.hippolyte-snippet
  width 50%
  margin auto
  height 98%
  overflow-y scroll
  ul
    list-style none
    padding 0
    li
      margin 1em
      form
        &.snippet_show
          cursor pointer
        padding 0 1em
        header
          display flex
          justify-content space-between
          align-items baseline
          margin 1em 1em 0 1.8em
        p
          margin 1.8em 2em
          word-break break-all
          cursor text
          width calc(100% - 4em)
  .selected
    border-radius 1em
    border 1px #828282 solid
    padding 1em
  button
    margin-left .5em
    border-radius 1.5em
    padding 0.5em 1em
    font-size 1em
    cursor pointer
  p
    button
      float right
      margin-right 3em
      border 1px #19d089 solid
      color #19d089
      background white
  .snippet_create
    margin-right 3em
</style>
