<template>
  <div class="hippolyte-snippets">
    <div class="loader" v-if="cancels.list">
      <loader></loader>
    </div>
    <template v-else>
      <div class="snippets-panel">
        <ul>
          <li @mousedown="needConciliation(item.template) ? '' : select(item)" v-for="item in items" :key="item.id" :class="needConciliation(item.template) && 'strike'" :title="title(item.template)">
            <header><b>{{item.name}}</b></header>
            <span class="snippet-text">
              <snippet
                :template="item.template"
                :candidate="candidate"
                :conciliation="conciliation"
                :linebreak="linebreak"
              ></snippet>
            </span>
          </li>
        </ul>
        <button @mousedown="$router.push({ name: 'snippet' })" class="profil-link">Ajouter un message rapide<span>+</span></button>
      </div>
    </template>
  </div>
</template>
<script>
import Loader from '/layout/spinner'
import Candidate from '/user/candidate/candidate.entity'
import Conciliation from '/interview/interview.js'
import cancel from '/cancel.mixin'
import Snippet from '/snippet/snippet'
import tagToText from '/snippet/text'

export default {
  name: 'hippolyte.snippets.list',
  components: { Loader, Snippet },
  props: {
    conciliation: Conciliation,
    candidate: Candidate,
    linebreak: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  mixins: [cancel],
  data () {
    return {
      items: [],
      recruiter: this.conciliation?.recruiter || null,
      search: this.conciliation?.search || null
    }
  },
  async mounted () {
    this.load()
  },
  emits: ['select'],
  methods: {
    title (template) {
      return this.needConciliation(template) ? 'inutilisable dans ce context' : 'Selectionner ce message rapide'
    },
    needConciliation (template) {
      if (this.conciliation) {
        return false
      }
      return template.some(el => {
        if (el === null) {
          return false
        }
        return  typeof el === 'object' && ['Search', 'Recruiter'].includes(el.entity)
      })
    },
    select (item) {
      this.$emit('select', this.toText(item.template))
    },
    async load () {
      this.cancel('list')
      try {
        const response = await this.$socket.service('chat.template/LIST', { cancel: this.token('list') })
        this.items.splice(0, this.items.length, ...response.docs)
        this.cancel('list', null)
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('list', null)
        })
      }
    },
    toText (template) {
      return template.reduce((text, el) => {
        if (el === null) {
          return text + '\n'
        }
        if (typeof el === 'string') {
          return text + el
        }
        if (typeof el === 'object') {
          return text + tagToText(el, { candidate: this.candidate, recruiter: this.recruiter, search: this.search })
        }
      }, '')
    }
  }
}
</script>
<style lang="stylus" scoped>
@require './spacer'
@require '../colors'
.hippolyte-snippets
  margin 0
  padding 0
  ul
    list-style none
    padding 0
    margin 0
    spacer-h()
    li
      spacer-v()
      margin 0
      padding 0
      cursor pointer
      color #8F9BB3
      &.strike
        background-color $color-blue_grey_light
        & > header, span
          text-decoration line-through
      & > *
        display inline-block
        padding .2em 1em .2em .5em
        margin .2em 0
        overflow-y auto
        overflow-x hidden
        white-space nowrap
        text-overflow ellipsis
      .snippet-text
        max-width 200px
        @media (min-width $bp-mobile)
          max-width 300px
      header
        max-width 25%
        color #8F9BB3
  button
    background $color-azur
    color $color-white
    font-size 1em
    float right
    padding 0.4em .4em 0.4em 2em
    vertical-align middle
    border-radius 1.5em
    span
      display inline-block
      margin-left 1em
      width 20px
      height 20px
      border 1px white solid
      font-size 18px
      line-height 20px
      border-radius 100%
      vertical-align middle
</style>
