<template>
  <div>
    <h1>En résumé</h1>
    <div v-if="busy">
      <spinner></spinner>
    </div>
    <div v-else class="section">
      <box
        class="box-left-align"
        description="Candidats envoyés avec au moins une question Screen AI répondue"
      >
        <div class="icon-container">
          <candidates-icon
            width="40px"
            height="40px"
            class="icon"
          ></candidates-icon>
        </div>
        <div class="text-container">
          <p class="stat">{{ sentCandidates }}</p>
          <p class="description">Candidats envoyés</p>
        </div>
      </box>
      <box
        title="Candidats"
        description="
Appelés : uniquement les appels lancés (exclut les faux numéros)

Ont décroché : appels où le candidat a décroché

Ont été au bout de l'appel : appels où le candidat est resté en ligne au moins 15 secondes"
      >
        <progress-bar :bars="callStates" :total="totalCalls"></progress-bar>
      </box>
      <box title="Fiabilité de la transcription vocale">
        <simpleDonut
          v-if="transcriptionConfidence"
          :coefficient="transcriptionConfidence"
        ></simpleDonut>
        <p v-else>Aucune information à afficher</p>
      </box>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Box from '/components/box'
import CandidatesIcon from '/icons/candidates'
import ProgressBar from '/reporting/progressBar'
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import SimpleDonut from '/components/simpleDonut'
import Spinner from '/layout/spinner'

const CancelToken = axios.CancelToken

export default {
  name: 'hippolyte.reporting.screenAi',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date
  },
  data() {
    return {
      busy: false,
      cancel: null,
      sentCandidates: 0,
      count_calls: 0,
      count_failed_calls: 0,
      callStates: [
        { title: 'Appelés', value: 0, style: 'success_calls' },
        { title: 'Ont décroché', value: 0, style: 'picked_up_calls' },
        {
          title: "Ont été au bout de l'appel",
          value: 0,
          style: 'completed_calls'
        }
      ],
      totalCalls: 0,
      transcriptionConfidence: 0
    }
  },
  watch: {
    search: 'debounce',
    recruiter: 'debounce',
    start: 'debounce',
    end: 'debounce',
    a: 'debounce'
  },
  components: {
    Box,
    CandidatesIcon,
    ProgressBar,
    SimpleDonut,
    Spinner
  },
  computed: {
    test() {
      return 0
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    if (this.cancel) {
      this.cancel.cancel()
    }
  },
  methods: {
    async load() {
      this.timeout = null
      this.busy = true
      if (this.cancel) {
        await this.cancel.cancel()
      }
      this.cancel = CancelToken.source()
      try {
        const stats = await Promise.all(
          [
            ['count_sent_candidates'],
            ['count_calls'],
            ['count_failed_calls', 'failed'],
            ['count_failed_calls', 'hangup'],
            ['count_success_calls'],
            ['get_average_confidence']
          ].map(async (item) => await this.requestStat(...item))
        )
        this.sentCandidates = stats[0].count_sent_candidates
        this.totalCalls = stats[1].count_calls
        this.callStates[0].value =
          stats[1].count_calls - stats[2].count_failed_calls
        this.callStates[1].value =
          stats[4].count_success_calls + stats[3].count_failed_calls
        this.callStates[2].value = stats[4].count_success_calls
        this.transcriptionConfidence = stats[5].get_average_confidence
      } catch (err) {
        if (!axios.isCancel(err)) {
          throw err
        }
      }
      this.cancel = null
      this.busy = false
    },
    requestStat(stat, reason = null) {
      return this.$socket.service(
        'voicebot_stats/GET',
        {
          stat,
          reason,
          start: this.start,
          end: this.end,
          search: this.search ? this.search.id : null,
          recruiter: this.recruiter ? this.recruiter.id : null
        },
        { cancel: this.cancel.token }
      )
    },
    debounce() {
      if (this.timeout) {
        return
      }
      const load = this.load.bind(this)
      this.timeout = setTimeout(load, 10)
    }
  }
}
</script>

<style lang="stylus">
@require '~/colors.styl'

.section
  display flex
  flex-direction row
  align-items flex-start
  gap 24px
  align-self stretch

  .box-left-align
    .content
      justify-content flex-start

.icon-container
    width 40px
    height 40px
    padding 12px 10px
    gap 10px
    border-radius 4px
    background rgba(22, 78, 226, 0.10)
  .icon
    width 40px
    height 35px
    stroke $hippolyte-blue
    fill: none
.text-container
  flex-shrink 0
  .stat
    margin 0px
    font-size 24px
    font-weight bolder
  .description
    margin-top 6px
    margin-bottom 0px

.legend_value
  font-size 20px
  font-weight bolder
.legend_title
  float right
  font-size 18px
.bar
  margin-top 8px
  margin-bottom 24px
.progress_bar
  width 100%
  .success_calls
    background-color $hippolyte-blue
  .picked_up_calls
    background-color $hippolyte-green
  .completed_calls
    background-color $hippolyte-orange

p
  leading-trim both
  text-edge cap
  font-size 20px
  font-family $font

h1
  font-size 24px
  font-weight bolder
  margin-bottom 24px
  margin-top 0px
  padding 15px
  padding-left 0px
h1::after
  content ''
  position relative
  background-color $hippolyte-green
  height 5px
  width 50px
  bottom -9px
  display block
</style>
