<template>
  <ats
    v-bind="$props"
    service="eolia"
    :savingConf="savingConf"
    :credential="{url: '', from: 'eolia@hippolyte-rh.fr', prefix: '', to: 'cv@eolia-data.com', subject: 'eolia'}"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="url">url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="from">email sender</label>
        <input v-model="credential.from" id="from" name="from"/>
        <modified :enable="value.credential.from !== credential.from"/>
      </div>
      <div>
        <label for="prefix">prefix job id</label>
        <input v-model="credential.prefix" id="prefix" name="prefix"/>
        <modified :enable="value.credential.prefix !== credential.prefix"/>
      </div>
      <div>
        <label for="to">email receipt</label>
        <input v-model="credential.to" id="to" name="to"/>
        <modified :enable="value.credential.to !== credential.to"/>
      </div>
      <div>
        <label for="subject">email subject</label>
        <input v-model="credential.subject" id="subject" name="subject"/>
        <modified :enable="value.credential.subject !== credential.subject"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.url" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.eolia.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.reference,
        job.title,
        job.ville
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
