<template>
  <section class="chat-menu">
    <button class="close-menu" @click="$emit('close')">&#10005;</button>
    <article class="requirement-list">
      <slot name="menu-header">
      </slot>
      <div class="search">
        <input type="text" v-model="search"/>
      </div>
      <div class="requirement-list_container">
        <button @click="toggleAllRequirement">Voir tout</button>
        <ul>
        <template v-for="(recruiter, index) in recruitersSortedByName">
          <li :key="recruiter.id" :class="(requirementsSorted.length > 1) ? '' : {isActive: showRequirements.includes(recruiter.id)}">
            <div  class="recruiter-item">
              <slot name="edit-button" :id="recruiter.id"></slot>
              <slot name="recruiter" :recruiter="recruiter" :toggleRequirements="toggleRequirements" :index="index" :id="recruiter.id"></slot>
              <slot name="counter" :id="recruiter.id" :getCount="getRecruiterCountById"></slot>
            </div>
            <div v-if="showRequirements.includes(recruiter.id)" :class="{isActive: showRequirements.includes(recruiter.id)}" class="requirement-infos">
              <template v-for="requirement in (requirementsSorted.length > 1) ? requirementsSorted[index] : requirementsSorted[0]">
                  <slot name="menu-search" :requirement="requirement"></slot>
              </template>
            </div>
          </li>
        </template>
        </ul>
      </div>
    </article>
  </section>
</template>
<script>
import Fuse from 'fuse.js'
import Requirement from '/requirement/entity.js'
import props from 'ramda/src/props'
import { Status } from '/interview/status'

export default {
  name: 'hippolyte.conciliator.menu.candidates',
  props: {
    selected: Array,
    selectedRecruiter: String
  },
  data () {
    return {
      id: null,
      requirements: this.$requirements,
      recruiters: this.$recruiters,
      search: '',
      hotOnly: false,
      filteredRequirements: [],
      showRequirements: [false]
    }
  },
  async mounted () {
    let recruiter = this.selectedRecruiter
    if (this.selected.length && !recruiter) {
      await this.$loading.requirements
      recruiter = this.recruiters.find(r => r.search.docs.find(s => this.selected.includes(s.id)))?.id
    }

    if (recruiter) {
      this.getRequirementsById(recruiter)
      this.showRequirements = [recruiter]
    }
  },
  methods: {
    toggleAllRequirement () {
      this.getAllRequirements()
      this.showRequirements = (this.showRequirements.length > 1) ? [false] : this.recruiters.map((r) => r.id)
    },
    getAllRequirements () {
      const requirementsList = this.recruiters.map(obj => obj.search.docs.map(r => new Requirement(r)))
      requirementsList.map(requirement => requirement.map(r => {
        const locations = r.location.docs
        locations.map(l => r.location = l)
        const trade = r.trade.docs
        trade.map(t => r.trade = t)
      }))
      this.filteredRequirements.splice(0,this.filteredRequirements.length, ...requirementsList)
    },
    toggleRequirements (index, id) {
      this.getRequirementsById(id)
      this.showRequirements = (this.showRequirements[0] === id) ? [false] : [id]
    },
    getRequirementsById (id) {
      const recruiter = this.recruiters.find(item => item.id === id)
      const requirementsList = recruiter.search.docs.map(r => new Requirement(r))
      requirementsList.map(r => {
        const locations = r.location.docs
        locations.map(l => r.location = l)
        const trade = r.trade.docs
        trade.map(t => r.trade = t)
      })
      this.filteredRequirements.splice(0,this.filteredRequirements.length, requirementsList)
    },
    getRecruiterCountById (id) {
      const recruiter = this.recruiters.find(item => item.id === id)
      return recruiter?.search.docs
        .reduce((acc, s) => {
          return acc +
            s['conciliation.suggested'].numFound +
            s['conciliation.created'].numFound +
            s['conciliation.accepted'].numFound
        }, 0)
    },
    getRequirementCount (requirement) {
      return props([Status.created, Status.suggested, Status.accepted].map(s => 'conciliation.' + s), requirement)
        .reduce((count, s) => count + s.numFound, 0)
    },
    sortByDate (a, b) {
      if (a.createdAt > b.createdAt) {
          return -1
      }
      if (a.createdAt < b.createdAt) {
          return 1
      }
      return 0
    }
  },
  computed: {
    recruitersSortedByName () {
      if (this.search.length === 0) {
        return this.recruiters.sort(function (a, b) {
          if (a.company < b.company) {
            return -1
          }
          if (a.company > b.company) {
            return 1
          }
          if (a.company === b.company) {
            if (a.createdAt > b.createdAt) {
              return -1
            }
            if (a.createdAt < b.createdAt) {
              return 1
            }
            return 0
          }
        })
      }

      const fuse = new Fuse(this.recruiters, {
        ignoreFieldNorm: true,
        ignoreLocation: true,
        keys: [
          { name: 'company', weight: 1 },
        ],
        includeScore: true
      })
      return fuse.search(this.search).map(i => i.item)
    },
    requirementsSorted () {
      return this.filteredRequirements.map(requirements => requirements.sort((a, b) => {
        const compare = this.getRequirementCount(b) - this.getRequirementCount(a)
        return compare || this.sortByDate(a, b)
      }))
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
$text = $color-white

.chat-menu {
  padding-top 1em
  position relative
  .close-menu {
    color #73c2bd
  }
  .requirement-list {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 1em;
    overflow-y: auto;
    header {
      font-size: 1em;
      margin-left: 1.5rem;
      &:after {
        margin-top: 0.4em;
        content: "";
        display: block;
        width: 20px;
        border: 2px #163ee2 solid;
        border-radius: 4px;
      }
      button {
        float right
        margin 0 1.2em
        padding 0
        background none
        border none
        img {
          width 1.2em
        }
        cursor pointer
      }
    }
    .search {
      position relative
      input {
        margin-top 1em
        width -webkit-fill-available
        padding-left 0.5em
        height 2em
        background alpha(#d5e4ef, 0.5) right 50% / 13% no-repeat url('/assets/icon/search.svg')
        color #404042
        font-size 1.2em
      }
      .active-switch {
        switch($color-yellow, $color-white)
        position absolute
        bottom -20px
        right 4px
      }
    }
    .requirement-list_container {
      margin-top: 0.5em;
      h4 {
        margin 0
        &:hover {
          cursor pointer
        }
      }

      ul {
        list-style none
        padding-left 0

        li:nth-child(even) {
          background alpha(#b8e0dd, 0.5)
        }

        li.isActive {
          box-sizing border-box
          background #b8e0dd
        }
      }

      .recruiter-item {
        display flex
        align-items center
        padding-left 0.2em
        color #404042
        position relative
        border-right 3px solid transparent
        box-sizing border-box

        button {
          width 1.6em
          padding 0
          svg {
            width 1.6em
          }
        }

        &:hover {
          border-right 3px solid #6b41b9
          background alpha(#000, 0.2)
        }

        .recruiter-counter {
          font-size 0.7em
          font-weight bold
          color $color-white
          right 0.2em
          width 1.6em
          height 1.6em
          display flex
          align-items center
          justify-content center
          padding 0.2em
          border-radius 50%
          background-color #45ADA7
        }
      }

      .recruiter-name {
        display flex
        align-items center
        text-align left
        padding 0.4em 0
        margin 0.2em 0.5em 0 0.5em
        width 75%

        &-content {
          margin 0 0.2em 0 0
          font-size 0.9em
        }
      }

      .requirement-infos {
        color #404042
      }

      .edit-search-btn {
        height 2em
        width 2.8em
        color white
        fill black
        display flex
        align-items center
        justify-content center
        padding 0.2em
        margin-right 0.4em
        background none
        &:hover {
          cursor pointer
        }

        &:hover svg {
          fill #6b41b9
          transform scale(1.1)
        }
      }

      .requirement-item_container {
        position relative
        color $color-menu_text
        color black
        padding-right: 0;
        border-right 3px solid transparent
        &:hover {
          background alpha(#000, 0.3)
          border-right 3px solid #6b41b9
          cursor pointer
        }
        &.selected {
          background alpha(#73c2bd, 0.5)
          border-right 3px solid #6b41b9

          &:hover {
            background alpha(#000, 0.3)
            }
        }

        .requirement-item_wrapper {
          margin-left 0.5rem
          padding 0.5em 0
          display flex
          flex-direction row
          align-items center

          .requirement-item_infos {
            width 80%

            .requirement-item_trade {
              font-size: 14px;
            }

            .requirement-item_created_at,
            .requirement-item_city {
              font-weight: bold;
              font-size: 12px;
            }
          }

          .requirement-item_active {
            width 20%
          }
        }
      }
    }
  }
}
</style>
