import pick from 'ramda/src/pick'
import { EventEmitter } from 'eventemitter3'
import Location from '/location/entity'
import Account from '/user/account/entity'
import { Solr, or, and, eq, search, insensitiveSearch } from '/solr'
const service = verb => `entity.Recruiter/${verb}`
const serviceStatus = verb => `entity.RecruiterStatus/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
const zip = (acc, v) => Object.assign(acc, { [v]: v })

const fields = ['id', 'company', 'siret', 'notificationEmails', 'country', 'city', 'location', 'plan', 'stripeId', 'subscribeUntil', 'freeProfiles', 'accounts']
function blank () {
  const me = fields.reduce((acc, val) => Object.assign(acc, { [val]: null }), {})
  me.accounts = []
  return me
}

function normalize (value) {
  return value
}

export default class Recruiter extends EventEmitter {
  constructor (data, socket) {
    super()
    this.custom = {}
    this.accounts = []
    this.setData(Object.assign({}, blank(), data))
    this.socket = socket
    this.loading = false
  }

  static create (data, socket) {
    return new Recruiter(data, socket)
  }

  setData (data) {
    const delta = this.getDelta()
    let surcharge = {}
    data = Object.assign({}, data)
    if (data.location) {
      if ('string' === typeof data.location) {
        surcharge.location = new Location({ id: data.location }, this.socket)
      } else if ('object' === typeof data.location) {
        surcharge.location = new Location(data.location, this.socket)
      }
    }
    if (data.accounts) {
      const accounts = data.accounts.map(s => {
        if (typeof s === 'undefined' || s === null) {
          return s
        }
        if (s instanceof Account) {
          return s
        }
        if (typeof s === 'string') {
          return new Account({ id: s })
        }
        if (typeof s === 'object') {
          return new Account(s)
        }
      }).filter(i => i)
      this.accounts.splice(0, this.accounts.length, ...accounts)
      delete data.accounts
    }
    Object.assign(this, data, surcharge)
    this.hasUpdate(delta)
    return this
  }

  getDelta (opts) {
    return JSON.stringify(this.marshall(opts))
  }

  hasUpdate (delta) {
    if (delta !== this.getDelta()) {
      this.emit('update')
    }
  }

  load () {
    if (!this.loading && this.id) {
      this.loading = this.socket.service(service('READ'), { id: this.id })
        .then(data => {
          this.loading = false
          return data
        })
        .then(normalize)
        .then(this.setData.bind(this))
    }
    return this.loading
  }

  save (serializer) {
    if (!this.saving) {
      const s = this.id ? service('SAVE') : 'recruiter.signin/CREATE'
      this.saving = this.socket.service(s, serializer ? serializer() : this.marshall())
        .then(data => {
          this.saving = false
          return data
        })
        .then(normalize)
        .then(this.setData.bind(this))
    }
    return this.saving
  }

  subscribe (args) {
    if (!this.saving) {
      this.saving = this.socket.service(service('SUBSCRIBE'), args)
        .then(data => {
          this.saving = false
          return data
        })
        .then(normalize)
        .then(this.setData.bind(this))
    }
    return this.saving
  }


  marshall (f=fields) {
    let data = pick(f, this)
    if (data.location instanceof Location) {
      data.location = data.location.marshall()
    }
    return data
  }
}

Recruiter.getByChatUser = async function (chatUser, socket) {
  let list = await socket.service(service('QUERY'), {
    alias: 'r',
    class: 'Recruiter',
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'r.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    return new Recruiter(list[0], socket)
  }
}

Recruiter.loadIds = async function (recruiters, socket, cancel) {
  if (recruiters.length < 1) {
    return
  }
  try {
    let list = await socket.service(service('QUERY'), {
      alias: 'r',
      class: 'Recruiter',
      parameters: [
        { type: cond('Parameter'), name: 'id', value: recruiters.map(c => c.id) }
      ],
      conditions: [
        {
          type: cond('In'),
          value: 'id',
          subject: {
            type: cond('Field'),
            name: 'r.id'
          }
        }
      ]
    }, { cancel })
    list.forEach(c => {
      recruiters.filter(i => i.id === c.id).map(c2 => c2.setData(normalize(c)).loading = false)
    })
  } catch (err) {
    recruiters.forEach(c => {
      c.loading = false
    })
  }
}
Recruiter.search = async function (opts = {}, socket, cancel) {
  if (opts.solr) {
    const req = new Solr(Object.assign({
      entity: 'Recruiter',
      raw: true
    }, { limit: opts.limit, offset: opts.offset }))
    if (opts.query) {
      req.query.push(opts.query)
    }
    const r = req.relation({
      entity: 'Search',
      name: 'searches',
      fromField: 'id',
      toField: 'recruiter',
      limit: 500
    })
    if (!('table' in opts)) {
      r.relation({
        entity: 'Trade',
        name: 'trade'
      }, {
        entity: 'Location',
        name: 'location'
      })
      req.sorts('company')
      if (opts.company) {
        const company = opts.company
        req.query.push(search({ insensitive: true }, 'company', company))
      }
    } else {
      req.fields = ['id', 'company']
      r.fields = ['title', 'id']
    }
    if ('active' in opts) {
      req.relations[0].query = 'active:' + opts.active
      req.join({
        entity: 'Search',
        query: 'active:' + opts.active,
        fromField: 'id',
        toField: 'recruiter'
      })
    }
    return socket.service('entity_solr/QUERY', req.send(), { cancel })
  } else {
    const search = Object.entries(opts)
    .filter(([f, v]) => !['limit', 'offset'].includes(f))
    .reduce((opts, [field, value]) => {
      return Object.assign({}, opts, {
        parameters: opts.parameters.concat([{
          type: cond('Parameter'),
          name: field,
          value: `%${value.toLowerCase()}%`
        }]),
        conditions: opts.conditions.concat([{
          type: cond('Like'),
          value: field,
          subject: {
            type: cond('Field'),
            name: `LOWER(r.${field})`
          }
        }])
      })
    }, {
      alias: 'r',
      class: 'Recruiter',
      limit: opts.limit || 10,
      offset: opts.limit || 0,
      parameters: [],
      conditions: []
    })
    const list = await socket.service(service('QUERY'), search, { cancel })
    return list.map(l => new Recruiter(l, socket))
  }
}

Recruiter.countStatus = async function (opts = {}, socket, cancel) {
  const req = new Solr(Object.assign({
    entity: 'Recruiter',
    raw: true,
  }, { limit: opts.limit, offset: opts.offset }))
  req.sorts('company')
  const active = { true: 'true', false: 'false' }[opts.active]
  req.relation({
    name:'accounts',
    entity:'RecruiterAccount',
    fromField: 'id',
    toField: 'recruiter',
  })
  const search = req.join({
    entity: 'Search',
    fromField: 'id',
    toField: 'recruiter',
  })

  const r = req.relation({
    entity: 'Search',
    name: 'search',
    fromField: 'id',
    toField: 'recruiter',
    limit: 500
  })
  r.relation({
    entity: 'Trade',
    name: 'trade'
  }, {
    entity: 'Location',
    fromField: 'location',
    toField: 'id',
    name: 'location'
  })
  if (active) {
    search.query.push(eq('active', active))
    r.query.push(eq('active', active))
  }
  if (opts.status && Array.isArray(opts.status)) {
    opts.status.map(status => {
      req.parameter(status)
      r.relation({
        entity: 'Conciliation',
        name: 'conciliation.' + status,
        fromField: 'id',
        toField: 'search',
        query: `{!join entity=ConciliationStatus v="current:true AND status:@${status}"}`,
        limit: 0
      })
    })
  }
  if (opts.recruiter) {
    req.query.push(eq('id', recruiter.id))
  }

  return socket.service('entity_solr/QUERY', req.send(), { cancel })
}
window.Recruiter = Recruiter
