<template>
  <div class="requirement-item_wrapper">
    <div class="requirement-item_infos">
      <button @click="openBoard" v-if="$isAdmin()" class="edit-search-btn">
        <svg
          viewBox="-15 -15 484.00019 484"
          xmlns="http://www.w3.org/2000/svg">
            <path d="m401.648438 18.234375c-24.394532-24.351563-63.898438-24.351563-88.292969 0l-22.101563 22.222656-235.269531 235.144531-.5.503907c-.121094.121093-.121094.25-.25.25-.25.375-.625.746093-.871094 1.121093 0 .125-.128906.125-.128906.25-.25.375-.371094.625-.625 1-.121094.125-.121094.246094-.246094.375-.125.375-.25.625-.378906 1 0 .121094-.121094.121094-.121094.25l-52.199219 156.96875c-1.53125 4.46875-.367187 9.417969 2.996094 12.734376 2.363282 2.332031 5.550782 3.636718 8.867188 3.625 1.355468-.023438 2.699218-.234376 3.996094-.625l156.847656-52.324219c.121094 0 .121094 0 .25-.121094.394531-.117187.773437-.285156 1.121094-.503906.097656-.011719.183593-.054688.253906-.121094.371094-.25.871094-.503906 1.246094-.753906.371093-.246094.75-.621094 1.125-.871094.125-.128906.246093-.128906.246093-.25.128907-.125.378907-.246094.503907-.5l257.371093-257.371094c24.351563-24.394531 24.351563-63.898437 0-88.289062zm-232.273438 353.148437-86.914062-86.910156 217.535156-217.535156 86.914062 86.910156zm-99.15625-63.808593 75.929688 75.925781-114.015626 37.960938zm347.664062-184.820313-13.238281 13.363282-86.917969-86.917969 13.367188-13.359375c14.621094-14.609375 38.320312-14.609375 52.945312 0l33.964844 33.964844c14.511719 14.6875 14.457032 38.332031-.121094 52.949218zm0 0"/>
        </svg>
      </button>
      <div class="requirement-item_infos-content">
        <div class="requirement-item_trade">{{ requirement.trade.name }}<i v-if="requirement.title"> - {{requirement.title}}</i></div>
        <div class="requirement-item_created_at">{{ requirement.createdAt | format('dd MMMM yyyy') }}</div>
        <div class="requirement-item_city">{{ requirement.location.city }}</div>
        <a href="" />
      </div>
    </div>
    <div class="requirement-counter">
      <span>{{ count }}</span>
    </div>
    <div class="requirement-item_active checkbox-container" v-show="showActivity">
      <input type="checkbox" disabled="disabled" v-model="requirement.active" :id="requirement.id" />
      <label :for="requirement.id"></label>
    </div>
  </div>
</template>
<script>
import Requirement from '/requirement/entity.js'
import props from 'ramda/src/props'
import { Status } from '/interview/status'

export default {
  name: 'hippolyte.menu.requirement.item',
  props: {
    requirement: Requirement,
    showActivity: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    showTag: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  mounted () {
    this.requirement.on('update', this.$forceUpdate, this)
  },
  computed: {
    count () {
      return props([Status.created, Status.suggested, Status.accepted].map(s => 'conciliation.' + s), this.requirement)
        .reduce((count, s) => count + s.numFound, 0)
    }
  },
  methods: {
    openBoard () {
      try {
        window.open([
          document.location.protocol, '',
          document.location.host.replace('conciliator', 'conciliateur'),
          'recruiter',
          this.requirement.recruiter.id,
          'search',
          this.requirement.id,
          'edit'
        ].join('/'))
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.requirement-tag
  float right
  font-size 0.8em
  color $color-astronaut
  background $color-yellow
  padding 0.1em 0.4em 0em 0.4em
  border-radius 1em

.requirement-item_wrapper
  border-right 3px solid transparent
  position relative
  background none

.requirement-item_infos
  position relative
  display flex
  align-items center
  padding 0.2em 0
  max-width 90%

  .requirement-item_infos-content
    width 80%

  .edit-search-btn
    height: 2em
    width 2.8em
    display flex
    align-items center
    justify-content center
    padding 0.2em
    margin-right 0.4em
    background none
    svg
      width 100%
      height 100%
    &:hover svg
      fill white
    img
      width 1em
      height: 1.2em
      margin 0
    &:hover
      cursor pointer
      color $color-yellow
.requirement-counter
  position absolute
  font-size 0.7em
  font-weight bold
  color $color-white
  right 0.4em
  top 30%
  width 1.6em
  height 1.6em
  display flex
  align-items center
  justify-content center
  padding 0.2em
  border-radius 50%
  background-color #8A8E9B
</style>
